import React, { useState } from 'react'
import { PageProps } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import {
  FiftyFiftyOne,
  FiftyFiftyTwo,
  Form,
  Head,
  Header,
  Lightbox,
  Link,
  SliderWithZoom,
} from '~/components'
import { Image } from '~/components/Lightbox'
import * as st from '~/assets/styl/Home.module.styl'
import cn from 'classnames'
import logo from '~/assets/svg/logo-green.svg'
import imgFifty01 from '~/assets/img/img-home-01.png'
import imgFifty02 from '~/assets/img/img-home-02.png'
import imgFull01 from '~/assets/img/img-home-03.png'
import imgFifty03 from '~/assets/img/img-home-04.png'
import imgFifty04 from '~/assets/img/img-home-05.png'
import imgFifty05 from '~/assets/img/img-home-06.png'
import imgFifty06 from '~/assets/img/img-home-07.png'
import videoMp4 from '~/assets/video/looping-marina.mp4'
import videoWebm from '~/assets/video/looping-marina.webm'
import imgFifty01Mobile from '~/assets/img/img-home-01-mobile.png'
import imgFifty02Mobile from '~/assets/img/img-home-02-mobile.png'
import imgFifty03Mobile from '~/assets/img/img-home-04-mobile.png'
import imgFifty04Mobile from '~/assets/img/img-home-05-mobile.png'
import imgFifty05Mobile from '~/assets/img/img-home-06-mobile.png'
import imgFifty06Mobile from '~/assets/img/img-home-07-mobile.png'
import imgBuildingPlanPng from '~/assets/img/img-building-plan.png'
import imgBuildingPlanJpg from '~/assets/img/img-building-plan.jpg'
import imgMap from '~/assets/img/img-map.png'
import imgIlhaCampeche from '~/assets/img/img-ilha-campeche.jpg'
import logoWhite from '~/assets/svg/logo-white.svg'
import logoFurtado from '~/assets/svg/logo-furtado.svg'

const imgLightBox = [
  {
    url: imgBuildingPlanJpg,
    legend: 'Planta do empreendimento Marina Sunshine Open City - Campeche',
  },
]

const Home = ({ location }: PageProps) => {
  const [index, setIndex] = useState(0)
  const [images, setImages] = useState<Array<Image>>()

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  return (
    <>
      <Head location={location} />
      <Header />
      <main className={st.core} id="topo">
        {/* <Link
          href="https://api.whatsapp.com/send?phone=554884026325"
          external
          title="Converse pelo WhatsApp com um de nossos corretores"
          className="btn-whats"
        ></Link> */}
        <div className={st.bkgTop}>
          <img
            src={logo}
            alt="Logo do empreendimento Marina Sunshine - Open city Campeche"
          />
          <h1 className={st.lotes}>
            Lotes de 450 a 600 m² <br /> prontos para construir.
          </h1>
          <Link href="#explorar">
            <span>Conheça o loteamento</span>
          </Link>
        </div>
        <div className={st.videoCore} id="explorar">
          <div className={st.textVideo}>
            <h2>Para morar ou investir no Campeche.</h2>
            <p>
              Amplos lotes à venda em uma localização privilegiada no Campeche.
              Perto de tudo, no coração da Ilha, respeitando os princípios do
              Novo Urbanismo.{' '}
            </p>
            <Link href="#projeto" className={st.linkLotes}>
              Ver lotes
            </Link>
          </div>
          <div className={st.video}>
            <video autoPlay muted loop height="800" width="1920">
              <source src={videoMp4} type="video/mp4" />
              <source src={videoWebm} type="video/webm" />
            </video>
          </div>
        </div>
        <div id="surpreenda-se"></div>
        <FiftyFiftyOne
          container={[
            {
              bkgColor: '#294E58',
              title: 'Um loteamento completo<br /> e para todos.',
              listItems: [
                'Largas e extensas ciclovias e passeios',
                'Vias largas e arborizadas',
                'Fiação subterrânea',
                'Praça completa',
                'Floresta urbana',
              ],
              hasBtn: false,
              btnHref: '#projeto',
              btnTitle: 'Ver lotes',
              imgSrc: imgFifty03,
              imgSrcMobile: imgFifty02Mobile,
              imgAlt: '',
            },
            {
              bkgColor: '#294E58',
              title: 'Diferenciais do Marina<br/> Sunshine:',
              text: 'O loteamento conta com quadras residenciais, ruas largas, passeio público em todos os terrenos, quadras mistas e ciclovia de mão dupla. São ambientes abertos que convidam as pessoas à convivência.',
              hasBtn: true,
              btnHref: '#fale-conosco',
              btnTitle: 'Solicite informações',
              imgSrc: imgFifty02,
              imgSrcMobile: imgFifty03Mobile,
              imgAlt: '',
            },
          ]}
        />
        {!isMobile && (
          <div
            className="container-bkg-full"
            style={{ backgroundImage: `url(${imgFull01})` }}
          >
            <div className="container">
              <p className="title giant-size text-right">
                Pessoas em primeiro lugar
              </p>
            </div>
          </div>
        )}
        <FiftyFiftyTwo
          container={[
            {
              bkgColor: '#964B2B',
              title: 'Por que escolher<br/> o Campeche? ',
              text: 'O Campeche é referência em desenvolvimento organizado, localizado no Sul da Ilha, lugar com maior índice de valorização em Florianópolis. O equilíbrio perfeito entre natureza e vida urbana.',
              hasBtn: false,
              btnHref: '#fale-conosco',
              btnTitle: 'Solicite informações',
              imgSrc: imgFifty06,
              imgSrcMobile: imgFifty06Mobile,
              imgAlt: '',
            },
            {
              bkgColor: '#964B2B',
              title: 'Um lugar onde é possível<br/> ter tudo ao seu alcance.',
              text: 'Seguindo as tendências do Novo Urbanismo, o Marina Sunshine é um loteamento que prioriza a alta proximidade com os principais serviços e estabelecimentos, como uma forma de descomplicar sua rotina com a praticidade.',
              imgSrc: imgFifty05,
              imgSrcMobile: imgFifty05Mobile,
              imgAlt: '',
              hasHeader: true,
            },
          ]}
        />
        {isMobile && (
          <div
            className="container-bkg-full"
            style={{ backgroundImage: `url(${imgFull01})` }}
          >
            <div className="container">
              <p className="title giant-size text-right">
                Pessoas em primeiro lugar
              </p>
            </div>
          </div>
        )}
        <SliderWithZoom />
        <div className={st.bkgPaints} id="projeto">
          <section className="container with-padding">
            <h2 className="title">
              Conheça o loteamento Marina <br /> Sunshine
            </h2>
            <p>
              Mais que terrenos, um ambiente que coloca pessoas em primeiro
              lugar, valoriza a convivência, proximidade e praticidade no dia a
              dia com alto potencial de valorização.
            </p>
            <ul className={st.projectNumberList}>
              <li>
                <i></i>
                <div>
                  <p>Área total</p>
                  <p>
                    <strong>96.596,05 m²</strong>
                  </p>
                </div>
              </li>
              <li>
                <i></i>
                <div>
                  <p>Área dos lotes</p>
                  <p>
                    <strong>51.896,31 m²</strong>
                  </p>
                </div>
              </li>
              <li>
                <i></i>
                <div>
                  <p>Total de lotes</p>
                  <p>
                    <strong>93 unidades</strong>
                  </p>
                </div>
              </li>
              <li>
                <i></i>
                <div>
                  <p>Área pública</p>
                  <p>
                    <strong>43.152,76 m²</strong>
                  </p>
                </div>
              </li>
              <li>
                <i></i>
                <div>
                  <p>Área de passeios e ruas</p>
                  <p>
                    <strong>23.725,40 m²</strong>
                  </p>
                </div>
              </li>
              <li>
                <i></i>
                <div>
                  <p>Área verde total</p>
                  <p>
                    <strong>14.351,46 m²</strong>
                  </p>
                </div>
              </li>
              <li>
                <i></i>
                <div>
                  <p>Área da praça</p>
                  <p>
                    <strong>3.077,57 m²</strong>
                  </p>
                </div>
              </li>
              <li>
                <i></i>
                <div>
                  <p>Lotes residenciais</p>
                  <p>
                    <strong>450 a 600m²</strong>
                  </p>
                </div>
              </li>
            </ul>
            <div className={st.containerBuildingBlan}>
              <img
                src={imgBuildingPlanPng}
                alt="Ilustração da planta do empreendimento Sunshine Open City Campeche"
                onClick={() => {
                  setIndex(index)
                  setImages(imgLightBox.map((i) => i))
                }}
              />
            </div>
            <Lightbox
              index={index}
              images={images}
              setImages={setImages}
              setIndex={setIndex}
            />
            <div className={st.flexBetween}>
              <p className={st.zoom}>Clique na imagem para ampliar</p>
              <Link href="#fale-conosco" className="btn btn-orange">
                Solicite Informações
              </Link>
            </div>
          </section>
        </div>
        <div className="bkg-green-dark" id="localizacao">
          <div
            className={cn(
              'container with-padding',
              st.flexBetween,
              st.customGrid,
              st.reverseMobile
            )}
          >
            <section className={cn('color-white', st.map)}>
              <h2 className="title">
                Rodovia SC 405, km 4, <br /> Campeche - Floripa - SC
              </h2>
              {/* <address>
                <strong className="text">
                  Rodovia SC 405, km 4, Campeche - Floripa - SC
                </strong>
              </address> */}
              <ul className="items-underline">
                <li className="text">9 min do Floripa Airport</li>
                <li className="text">5 min da praia</li>
                <li className="text">7 min do novo acesso ao Sul da Ilha</li>
                <li className="text">4 min da Lagoa do Peri</li>
              </ul>
              <Link href="#fale-conosco" className="btn btn-orange">
                Solicite Informações
              </Link>
            </section>
            <figure>
              <img src={imgMap} alt="Mapa de Florianópolis - SC" />
            </figure>
          </div>
        </div>
        <div
          className={cn(st.flexBetween, st.fiftyFifty, st.reverse)}
          id="fale-conosco"
        >
          <div className={cn('bkg-orange-dark', st.formContainer)}>
            {isMobile && (
              <img
                src={imgIlhaCampeche}
                alt="Foto aérea de um nascer do sol em Florianópolis, no centro da foto encontra-se a ilha do Campeche"
              />
            )}
            <section className="color-white">
              <h2 className="title">
                More ou invista <span> no Campeche</span>
              </h2>
              <p>
                Quer receber um contato com mais informações dos terrenos
                disponíveis? Preencha o formulário abaixo!
              </p>
              <Form
                hiddenInputs={{
                  token_rdstation: 'c6c675ffa92de44ebf13451ae3e3df9d',
                  identificador: 'Sunshine-site',
                  privacy_policy: 1,
                  communications: 1,
                  legal_bases: [
                    {
                      category: 'communications',
                      type: 'consent',
                      status: 'granted',
                    },
                    {
                      category: 'data_processing',
                      type: 'consent',
                      status: 'granted',
                    },
                  ],
                }}
                inputs={[
                  {
                    name: 'nome',
                    label: 'Nome',
                    placeholder: 'Qual seu nome?',
                    type: 'text',
                    required: true,
                  },
                  {
                    name: 'email',
                    label: 'E-mail',
                    placeholder: 'Digite seu melhor e-mail',
                    type: 'email',
                    required: true,
                  },
                  {
                    name: 'telefone',
                    label: 'Telefone',
                    placeholder: '(DDD) Número',
                    type: 'text',
                    mask: 'phone',
                    required: true,
                  },
                  {
                    name: 'cf_quanto_voce_pretende_investir_no_seu_terreno',
                    label: 'Quanto você pretende investir no seu terreno?',
                    type: 'select',
                    options: [
                      {
                        name: 'Selecione',
                        value: '',
                      },
                      {
                        name: 'R$800.000 a R$1.000.000',
                        value: 'R$800.000 a R$1.000.000',
                      },
                      {
                        name: 'R$1.000.000 a R$1.500.000',
                        value: 'R$1.000.000 a R$1.500.000',
                      },
                    ],
                    required: true,
                  },
                ]}
                button={
                  <button type="submit" className="btn btn-green-dark">
                    Solicite Informações
                  </button>
                }
                loadingButton={
                  <button className="btn btn-green-dark">Enviando...</button>
                }
                idPrefix={'contact'}
                path={'conversions'}
              />
            </section>
          </div>
          <div>
            {!isMobile && (
              <img
                src={imgIlhaCampeche}
                alt="Foto aérea de um nascer do sol em Florianópolis, no centro da foto encontra-se a ilha do Campeche"
              />
            )}
            <div className="bkg-green-dark color-white">
              <footer>
                <Link
                  href="https://www.tiki.com.br/"
                  external
                  className="font-tiki"
                >
                  Tiki
                </Link>
                <div>
                  <img
                    src={logoWhite}
                    alt="Logo da Sunshine Open City - Campeche"
                  />
                  <Link href="https://www.fmendonca.com.br/" external>
                    <img src={logoFurtado} alt="Logo da Furtado de Mendonça" />
                  </Link>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Home
