// extracted by mini-css-extract-plugin
export var backgroundVideo = "Home-module--background-video--SLHJX";
export var bkgDown = "Home-module--bkg-down--djHCd";
export var bkgPaints = "Home-module--bkg-paints--RUB5i";
export var bkgTop = "Home-module--bkg-top--mOaNy";
export var containerBuildingBlan = "Home-module--container-building-blan--bhD-3";
export var core = "Home-module--core--rS7PR";
export var customGrid = "Home-module--custom-grid--ffuvb";
export var fiftyFifty = "Home-module--fifty-fifty--PJcP6";
export var flexBetween = "Home-module--flex-between--wbt3K";
export var formContainer = "Home-module--form-container--skFix";
export var linkLotes = "Home-module--link-lotes--sHIOa";
export var lotes = "Home-module--lotes--2VngN";
export var map = "Home-module--map--P9Jwk";
export var projectNumberList = "Home-module--project-number-list--PaUKt";
export var pulse = "Home-module--pulse--l9XXG";
export var reverse = "Home-module--reverse--0pXSb";
export var reverseMobile = "Home-module--reverse-mobile--W+Xy7";
export var textVideo = "Home-module--text-video---qGIz";
export var video = "Home-module--video--mDerF";
export var videoCore = "Home-module--video-core--qsMqE";
export var zoom = "Home-module--zoom--hfEQ9";